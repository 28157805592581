import React, { useState, Fragment } from 'react';
import gql from 'graphql-tag';
import ReactHtmlParser from 'react-html-parser';
import fp from 'lodash/fp';
import { Icon } from 'semantic-ui-react';

import { useQuery } from '<src>/apollo/client';

import WaveSpinner from '<src>/components/WaveSpinner';
import SearchBar from '<components>/SearchBar';

import * as styles from './styles';
import ArticleContextGuide from './ArticleContextGuide';
import sortHelpArticles from './utils';

export const GetCollectionsAndArticles = gql`
  query GetCollectionsAndArticles {
    collections {
      type
      id
      name
      description
      order
    }
    articles {
      type
      id
      title
      description
      body
      state
      parent_id
    }
  }
`;

function getRecommendedArticles(articles) {
  let items = [];
  const paths = window.location.pathname.split('/');
  items = articles.filter((a) => {
    const articleContext = ArticleContextGuide.find(
      (aC) => aC.articleID === a.id
    );
    if (articleContext) {
      return articleContext.keywords.some((c) => paths.indexOf(c) >= 0);
    }
    return false;
  });
  return items;
}

export default function HelpSidebar({ onClose, visible = false }) {
  const { data: { collections = [], articles = [] } = {}, loading } = useQuery(
    GetCollectionsAndArticles,
    {
      fetchPolicy: 'cache-first',
    }
  );

  const defViewMode = ['recommended-articles', 'all-collections'];
  const [navigation = [], setNavigation] = useState();
  const [viewMode = defViewMode, setViewMode] = useState();
  // view mode can be all-collections, all-articles, collection, or article
  // or recommended-articles based on current path
  const [activeArticles = [], setActiveArticles] = useState();
  const [activeArticle = {}, setActiveArticle] = useState();
  const recommendedArticles = getRecommendedArticles(articles || []);
  const [search = '', setSearch] = useState();

  const setActiveItem = (type, item, skipUpdateNav) => {
    let newNavigation = [...navigation];
    if (type !== 'collection') {
      setActiveArticle(item);
    }
    setViewMode([type]);
    setSearch('');

    if (type === 'collection') {
      const collectionArticles = articles.filter(
        (a) => a.parent_id === item.id
      );
      newNavigation.unshift({
        type: 'collection',
        id: item.id,
        name: item.name,
      });

      setActiveArticles(collectionArticles);
    }
    if (type === 'article') {
      newNavigation.unshift({
        type: 'article',
        id: item.id,
        title: item.title,
      });
    }

    // insert new nav item
    !skipUpdateNav && setNavigation(newNavigation);
  };

  // navigation logic
  const navigateBack = () => {
    // remove current item from navigation
    let newNav = [...navigation];
    newNav.shift();
    setNavigation(newNav);
    // update view mode
    const lastItem = newNav[newNav.length - 1];

    if (lastItem) setActiveItem(lastItem.type, lastItem, true);
    // if no lastItem, set the viewMode to all-collections;
    if (!lastItem) setViewMode(defViewMode);
  };
  const renderPreviousItem = () => {
    const previousItem = navigation[navigation.length - 1];

    if (navigation.length > 0) {
      const title =
        navigation.length === 1
          ? 'Back'
          : previousItem.title || previousItem.name;
      return (
        <styles.BackBtn onClick={navigateBack}>
          <Icon name="chevron left" />
          <span>{title}</span>
        </styles.BackBtn>
      );
    } else {
      return null;
    }
  };

  const renderCurrentItem = () => {
    const currentItem = navigation[0];

    return currentItem ? (
      <styles.CurrentTitle>
        {currentItem.title || currentItem.name}
      </styles.CurrentTitle>
    ) : null;
  };

  const handleSearch = (value) => {
    setSearch(value);
    if (
      value &&
      !viewMode.includes('all-articles') &&
      !viewMode.includes('collection')
    ) {
      setViewMode(['all-articles', ...viewMode]);
    } else {
      setViewMode(viewMode.filter((m) => m !== 'all-articles'));
    }
  };

  const getFilteredItems = (items) => {
    return items.filter(
      (i) =>
        fp.getOr('', 'name', i).toLowerCase().includes(search.toLowerCase()) ||
        fp.getOr('', 'title', i).toLowerCase().includes(search.toLowerCase())
    );
  };

  const getItemTitle = (value) => {
    if (!value) return '';
    if (!search) return value;
    const searchPattern = new RegExp(search, 'ig');
    if (!searchPattern) return value;

    const searchTermLength = search.length;
    const matches = [];
    let match;

    while ((match = searchPattern.exec(value))) matches.push(match.index);

    const parts = matches
      .reduce((acc, match) => {
        const overlappedSpan = acc.find(
          (span) => match > span[0] && match <= span[1]
        );

        if (overlappedSpan) {
          overlappedSpan[1] = match + searchTermLength;
        } else {
          acc.push([match, match + searchTermLength]);
        }

        return acc;
      }, [])
      .reduce((acc, [start, end], index, array) => {
        // make sure the first of the string is put on
        if (index === 0 && start !== 0) {
          acc.push(value.substring(0, start));
        }

        // add the part of the string that's highlighted
        const segment = value.substring(start, end);
        acc.push(
          <styles.Highlighted key={`${segment}${index}`}>
            {segment}
          </styles.Highlighted>
        );

        // add the next part of the string
        // make sure the last of the string is put on
        if (index + 1 === array.length && end !== array.length - 1) {
          acc.push(value.substring(end, value.length));
        } else {
          acc.push(value.substring(end, array[index + 1][0]));
        }

        return acc;
      }, []);

    return parts;
  };

  const renderList = (viewMode, key) => {
    let items = [];
    let header;
    const itemType = viewMode === 'all-collections' ? 'collection' : 'article';
    switch (viewMode) {
      case 'all-collections':
        items = [...collections].sort((a, b) => (a.order < b.order ? -1 : 1));
        header = 'COLLECTIONS';
        break;
      case 'collection':
        items = sortHelpArticles(activeArticles);
        header = 'ARTICLES';
        break;
      case 'recommended-articles':
        items = recommendedArticles;
        header = 'RECOMMENDED ARTICLES';
        break;
      case 'all-articles':
        items = articles;
        header = 'ARTICLES';
        break;
      default:
        return null;
    }
    if (search) items = getFilteredItems(items);

    return items.length > 0 ? (
      <styles.ListWrapper key={key}>
        <h4>{header}</h4>
        <styles.ItemList>
          {items.map((item, idx) => (
            <styles.ListItem
              key={idx}
              onClick={() => setActiveItem(itemType, item)}
            >
              <styles.ItemTitle>
                {getItemTitle(item.name || item.title)}
              </styles.ItemTitle>
              {item.description && (
                <styles.ItemDescription>
                  {item.description}
                </styles.ItemDescription>
              )}
            </styles.ListItem>
          ))}
        </styles.ItemList>
        <div className="shadow shadow--bottom"></div>
      </styles.ListWrapper>
    ) : null;
  };

  return visible ? (
    <styles.MenuSidebar>
      <styles.Navbar>
        <span>{renderPreviousItem()}</span>
        {navigation.length === 0 && <span>Help</span>}
        <styles.CloseBtn name="close" onClick={onClose} />
      </styles.Navbar>
      {loading ? (
        <WaveSpinner />
      ) : (
        <Fragment>
          {!viewMode.includes('article') && (
            <styles.SearchBarWrapper>
              <SearchBar
                value={search}
                onChange={handleSearch}
                darkMode="true"
              />
            </styles.SearchBarWrapper>
          )}

          {renderCurrentItem()}
          <styles.ContentWrapper>
            {viewMode.length > 0
              ? viewMode.map((vM, idx) => renderList(vM, idx))
              : null}

            {viewMode.includes('article') && activeArticle && (
              <styles.ArticleContainer>
                <div> {ReactHtmlParser(activeArticle.body)} </div>
              </styles.ArticleContainer>
            )}
          </styles.ContentWrapper>
        </Fragment>
      )}

      <styles.Footer>
        <span>Can't find what you are looking for?</span>
        <styles.TeamChatBtn
          onClick={(e) => {
            e.preventDefault();

            window.Intercom('show');
          }}
        >
          Chat with our team
        </styles.TeamChatBtn>
      </styles.Footer>
    </styles.MenuSidebar>
  ) : null;
}
