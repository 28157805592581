import React from 'react';

import { useQuery } from '<src>/apollo/client';

import FlowPage from '<sections>/flows/FlowPage';
import FlowPageV5 from '<sections>/flows/FlowPageV5';

import WaveSpinner from '<components>/WaveSpinner';

import { GetProjectName } from '../../queries';
import { GetFlow } from '<sections>/flows/api/queries';

export default function ProjectFlowPage({ projectID, flowID, history, match }) {
  const { data, error, loading } = useQuery(GetProjectName, {
    variables: { ID: projectID },
  });

  const {
    loading: loadingFlow,
    error: flowError,
    data: flowData,
  } = useQuery(GetFlow, {
    variables: { flowID },
    fetchPolicy: 'network-only',
  });

  if (error || flowError) {
    throw error || flowError;
  }
  if (loading || loadingFlow) {
    return <WaveSpinner />;
  }

  const { project } = data;

  return flowData?.flow?.flow?.formatVersion === 5 ? (
    <FlowPageV5
      baseURL={`/models/${project.ID}`}
      project={project}
      flowID={flowID}
      flowData={flowData}
      loaddingFlow={loadingFlow}
      flowError={flowError}
      history={history}
      match={match}
    />
  ) : (
    <FlowPage
      baseURL={`/models/${project.ID}`}
      project={project}
      flowID={flowID}
      flowData={flowData}
      loaddingFlow={loadingFlow}
      flowError={flowError}
      history={history}
      match={match}
    />
  );
}
