import React, { useRef, useEffect, useContext } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { UserPreferencesContext } from '<components>/UserPreferences';

import {
  FlowTestHeader,
  FlowTestHeaderSeparator,
} from '../flow-testing/styles';
import Button from '<src>/components/Button';
import { TitleField } from '../styles';
import styled from '@emotion/styled';
import * as colors from '<components>/colors';

const StyledHdr = styled('h5')`
  a {
    color: ${colors.gray1};
    :hover {
      color: ${colors.blue1};
    }
  }
`;

export default function FlowMasthead({
  enterTestMode,
  testMode,
  testFeature,
  title,
  onTitleChange,
  flowID,
  maxTitleWidth,
  hideTitle,
  children,
  titleNavPath,
}) {
  const location = useLocation();
  const nameRef = useRef();
  const canEdit = !!onTitleChange;

  const { flowSettings, setFlowSetting } = useContext(UserPreferencesContext);

  const settings = flowSettings && flowID ? flowSettings[flowID] : {};
  const leftSidebar =
    settings && typeof settings.leftSidebar !== 'undefined'
      ? settings.leftSidebar
      : true;

  const titleText = titleNavPath ? (
    <NavLink to={titleNavPath}>{title}</NavLink>
  ) : (
    title
  );

  useEffect(() => {
    if (canEdit && location.search === '?new') {
      nameRef.current.edit(true);
    }
  }, [canEdit, location.search]);

  return (
    <FlowTestHeader>
      <div>
        {!leftSidebar && (
          <Button.SidebarShowBtn
            onClick={() => setFlowSetting(flowID, 'leftSidebar', true)}
          />
        )}

        {onTitleChange && (
          <h5>
            <TitleField
              ref={nameRef}
              placeholder="Click to edit"
              singleClick
              value={title}
              onSubmit={onTitleChange}
              maxTitleWidth={maxTitleWidth}
            />
          </h5>
        )}

        {!onTitleChange && !hideTitle && title && (
          <StyledHdr>{titleText}</StyledHdr>
        )}
        {children}
      </div>
      {!testMode && testFeature ? (
        <div>
          <FlowTestHeaderSeparator />
          <Button.TestFlowBtn title="Test flow" onClick={enterTestMode}>
            Test Flow
          </Button.TestFlowBtn>
        </div>
      ) : null}
    </FlowTestHeader>
  );
}
