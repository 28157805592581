import React, { useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import { RoundedContentWrapper } from '<src>/components/NumbrzVerticalEditor';
import { formatISODateString } from '<src>/utils/dates';
import { EmptyListContainer } from '<src>/components/NumbrzPageComponents';
import WaveSpinner from '<src>/components/WaveSpinner';
import { getStatusChiclet } from '<sections>/job-groups/utils';
import { StatusTable, DetailsBtn } from '../styles';

export default function RecentRuns({
  baseURL,
  projectID,
  jobID,
  recentRuns,
  startPolling,
  stopPolling,
  loading,
  loadRunHistory,
  calledRunHistory,
  calledJobStatus,
  jobRunning,
}) {
  const history = useHistory();
  useEffect(() => {
    if (
      (!calledRunHistory && !loading) ||
      (calledJobStatus && recentRuns.length === 0)
    )
      loadRunHistory();

    jobRunning ? startPolling(10000) : stopPolling();

    return () => {
      stopPolling();
    };
  }, [
    recentRuns,
    loading,
    jobRunning,
    calledRunHistory,
    calledJobStatus,
    loadRunHistory,
    startPolling,
    stopPolling,
  ]);

  return loading ? (
    <WaveSpinner />
  ) : !loading && (!recentRuns || recentRuns.length === 0) ? (
    <EmptyListContainer>
      <h4>No run history found </h4>
    </EmptyListContainer>
  ) : (
    <RoundedContentWrapper>
      <StatusTable style={{ width: '100%' }}>
        <thead>
          <tr>
            <th>Status</th>
            <th>Run By</th>
            <th>Date</th>
            <th>Duration</th>
            <th className="centered">Details</th>
          </tr>
        </thead>
        <tbody>
          {recentRuns.map((r, idx) => {
            const formattedStartedTime = formatISODateString(r.started, true);
            return (
              <tr key={idx}>
                <td>{getStatusChiclet(r.status)}</td>

                <td className="userName">
                  {r.runByName && (
                    <div>
                      <Icon name="user outline" />
                      <span>{r.runByName}</span>
                    </div>
                  )}
                </td>
                <td className="date">
                  {formattedStartedTime.includes('undefined') ? null : (
                    <div>
                      <Icon name="clock outline" />
                      <span>{formattedStartedTime}</span>
                    </div>
                  )}
                </td>
                <td className="duration">
                  <div>
                    <Icon name="hourglass outline" />
                    <span>{r.elapsed}</span>
                  </div>
                </td>
                <td className="centered">
                  <DetailsBtn
                    onClick={() => history.push(`${baseURL}/status/${r.runID}`)}
                  >
                    <Icon name="list alternate outline" />
                  </DetailsBtn>
                </td>
              </tr>
            );
          })}
        </tbody>
      </StatusTable>
    </RoundedContentWrapper>
  );
}
