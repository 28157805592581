import React, { Fragment } from 'react';
import Description from '<src>/components/Description';
import { NavLink } from 'react-router-dom';

import { NumbrzTooltip } from '<components>/NumbrzButtons';
import { ErrorChiclet } from '<components>/NumbrzPageComponents';
import { Icon } from 'semantic-ui-react';
import { Title, FlowListItem, ItemChiclet } from '../FlowItemListV5';
import { TablesetCounter } from '<sections>/jobs/styles';
import UnderlinedHeader from '<components>/UnderlinedHeader';

function renderRunItem({ runItem, index, containerID, flowID }) {
  // runItem can either be a stage or an embedded flow
  const itemContents = runItem.stage ? runItem.stage : runItem.flow;
  const isStage = runItem.stage !== null;
  const navPath = isStage
    ? `/models/${containerID}/flows/${flowID}/stages/${itemContents.stageID}`
    : `/models/${containerID}/flows/${itemContents.flowID}/stages`;
  return (
    <NavLink to={navPath} key={index}>
      <FlowListItem style={{ marginBottom: '10px' }}>
        <NumbrzTooltip
          content={`${isStage ? 'Stage' : 'Flow'} contains issues`}
          trigger={
            <ErrorChiclet visible={!itemContents.runnable ? 'true' : 'false'}>
              <Icon name="circle" />
              <Icon name="exclamation" title="This item contains issues" />
            </ErrorChiclet>
          }
        />
        <Title>
          <TablesetCounter fontSize="14px">{index + 1}</TablesetCounter>
          <h4>{itemContents.name}</h4>
        </Title>
        {itemContents.flowID && (
          <ItemChiclet margin="0 10px 0 5px">Flow</ItemChiclet>
        )}
      </FlowListItem>
    </NavLink>
  );
}

export default function EmbeddedFlowViewer({ embeddedFlow, loading, baseURL }) {
  return loading ? null : (
    <Fragment>
      {embeddedFlow.comment && (
        <Description
          hideIfEmpty
          content={embeddedFlow.comment}
          onChange={null}
          placeholder="No description provided"
          editable={false}
          margin="0 0 10px 0"
        />
      )}
      {embeddedFlow.runSequence.length > 0 && (
        <UnderlinedHeader margin="10px 0 8px 0" padding="0 10px 3px 10px">
          EMBEDDED STAGES
        </UnderlinedHeader>
      )}
      {embeddedFlow.runSequence.map((runItem, index) =>
        renderRunItem({
          runItem,
          index,
          containerID: embeddedFlow.containerID,
          flowID: embeddedFlow.ID,
        })
      )}
    </Fragment>
  );
}
