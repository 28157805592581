import React, { Fragment, useState } from 'react';
import T from 'prop-types';
import fp from 'lodash/fp';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

import { useMutation, useQuery } from '<src>/apollo/client';

import { CreateJob } from '<sections>/jobs/queries';

import Button from '<components>/Button';
import Dialog from '<components>/Dialog';
import TablesetPicker from '<components>/TablesetPicker';
import WaveSpinner from '<components>/WaveSpinner';
import useFeatures from '<components>/Feature/useFeatures';

import { GetProjectJobs, GetProjectFlows } from '../../../../queries';

import JobDetails from './JobDetails';

const StyledFooter = styled(Dialog.Footer)`
  border-top: none;
`;

const StyledBody = styled(Dialog.Body)`
  padding-top: 10px;
  min-width: 550px;
`;

AddJobForm.propTypes = {
  projectID: T.string,
  flows: T.array,
};

export default function AddJobForm({
  projectID,
  dialog,
  sourceProjectID = projectID,
  extraJobProps = {},
  allowAutomap = true,
  modelsPath = '/models',
  jobsPath = '/jobs',
}) {
  const history = useHistory();
  const featureFlags = useFeatures();
  const { data: { project } = {}, loading } = useQuery(GetProjectFlows, {
    variables: { ID: sourceProjectID },
  });
  const flows = loading ? [] : project.flows;
  const [createJob] = useMutation(CreateJob, {
    update: (cache, { data }) => {
      const { project } = cache.readQuery({
        query: GetProjectJobs,
        variables: { ID: projectID },
      });
      const newJob = data.createJob;
      cache.writeQuery({
        query: GetProjectJobs,
        variables: { ID: projectID },
        data: {
          project: {
            ...project,
            jobs: project.jobs.concat([newJob]),
          },
        },
      });
    },
  });

  const [name = '', setName] = useState();
  const [flowID = null, setFlowID] = useState();
  const [tablesetID = null, setTablesetID] = useState();
  const [creating = false, setCreating] = useState();
  const [mappingMode, setMappingMode] = useState(
    allowAutomap ? 'auto' : 'manual'
  );
  const [step = 'jobDetail', setStep] = useState();

  const isValid = () => {
    if (!name || !name.trim || !name.trim() || !flowID) return false;
    if (step === 'selectData' && !tablesetID) return false;

    return true;
  };

  const handleChange = (fieldName, value) => {
    if (fieldName === 'flowID') {
      const prevFlow = flows.find((f) => f.ID === flowID);
      const newFlow = flows.find((f) => f.ID === value);

      const prevName = prevFlow && `Run ${prevFlow.name}`;
      const newName = newFlow && `Run ${newFlow.name}`;
      const jobName = !name || name === prevName ? newName : name;

      setFlowID(value);
      setName(jobName);
    }
    if (fieldName === 'name') setName(value);
    if (fieldName === 'mappingMode') setMappingMode(value);
  };

  const handleSubmit = async () => {
    if (!isValid()) return;

    if (step === 'jobDetail' && mappingMode === 'auto') {
      setStep('selectData');
      return;
    }
    setCreating(true);

    const res = await createJob({
      variables: {
        input: {
          ...extraJobProps,
          name,
          projectID,
          flow: {
            activeVersionID: flowID,
          },
          tablesetID,
          tablesets: [{ ID: tablesetID }],
        },
      },
    });

    // clear state
    if (fp.getOr(undefined, 'data.createJob', res)) {
      setName('');
      setTablesetID(null);
      setCreating(false);
      setMappingMode('manual');
      setStep('jobDetail');

      history.push(
        `${modelsPath}/${projectID}${jobsPath}/${res.data.createJob.ID}`
      );
    }
  };

  const buttonBar = () => {
    if (step === 'selectData') {
      return (
        <Fragment>
          <Button id="back" onClick={() => setStep('jobDetail')}>
            Back
          </Button>
          <Button
            id="create"
            onClick={handleSubmit}
            disabled={creating || !isValid()}
          >
            {creating ? 'Creating...' : 'Create'}
          </Button>
        </Fragment>
      );
    }

    // implied else; step === 'jobDetail'
    if (mappingMode === 'manual') {
      return (
        <Fragment>
          <Button
            id="create"
            onClick={handleSubmit}
            disabled={creating || !isValid()}
          >
            {creating ? 'Creating...' : 'Create'}
          </Button>
        </Fragment>
      );
    }

    // implied else; mappingMode === 'auto'
    return (
      <Fragment>
        <Button
          id="next"
          onClick={() => setStep('selectData')}
          disabled={!isValid()}
        >
          Next
        </Button>
      </Fragment>
    );
  };

  const body = () => {
    if (loading) return <WaveSpinner />;

    if (step === 'jobDetail') {
      return (
        <JobDetails
          featureFlags={featureFlags}
          disabled={creating}
          allowAutomap={allowAutomap}
          mappingMode={mappingMode}
          name={name}
          flows={flows}
          flowID={flowID}
          onChange={handleChange}
          onSubmit={handleSubmit}
        />
      );
    } else if (step === 'selectData') {
      return (
        <TablesetPicker
          dialog={dialog}
          onChange={(tablesetID) => setTablesetID(tablesetID)}
          selectedID={tablesetID}
        />
      );
    }

    return null;
  };

  return (
    <Fragment>
      <StyledBody>{body()}</StyledBody>
      <StyledFooter>{buttonBar()}</StyledFooter>
    </Fragment>
  );
}
