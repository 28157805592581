import React, { useState, Fragment } from 'react';

import AddNameDialog from '<src>/components/AddNameDialog';
import AddJobTableDialog from '../AddJobTableDialog';
import {
  FooterContainer,
  Separator,
  FooterContent,
} from '<src>/components/NumbrzVerticalEditor';
import Button from '<src>/components/Button';
import AddStageDialog from '../AddStageDialog';

export default function SidebarFooter({
  flowID,
  label,
  actionLabel = 'Add',
  onAddItem,
  objType = 'Item',
  skipNameDialog = false,
  dataTables,
  dataTablesLoading,
}) {
  const [dialogVisible, setDialogVisible] = useState(false);

  const onAddClicked = skipNameDialog
    ? onAddItem
    : () => setDialogVisible(true);

  const DialogComp =
    objType === 'Stage'
      ? AddStageDialog
      : objType === 'job table' || objType === 'working table'
        ? AddJobTableDialog
        : AddNameDialog;

  const DialogAddProps =
    objType === 'job table' || objType === 'working table'
      ? { dataTables, dataTablesLoading }
      : {};

  return (
    <Fragment>
      <FooterContainer>
        <Separator />
        <FooterContent>
          {onAddItem ? (
            <Button.LeftSidebarCreateBtn
              onClick={onAddClicked}
              title={`${actionLabel} ${label}`}
            >
              {`${actionLabel} ${label}`}

              <span className="icon">+</span>
            </Button.LeftSidebarCreateBtn>
          ) : null}
        </FooterContent>
        {!skipNameDialog ? (
          <DialogComp
            flowID={flowID}
            objType={objType}
            onClose={() => setDialogVisible(false)}
            onCreate={onAddItem}
            visible={dialogVisible}
            {...DialogAddProps}
          />
        ) : null}
      </FooterContainer>
    </Fragment>
  );
}
