import gql from 'graphql-tag';

export const ElementFragment = gql`
  fragment ElementFragment on Element {
    key
    label
    type
    source
    comment
  }
`;

export const StageElementFragment = gql`
  fragment StageElementFragment on StageElement {
    key
    stageID
    label
    comment
    type
    source
    sourceInfo @client
  }
`;

export const IssueFragment = gql`
  fragment IssueFragment on ValidationIssue {
    class
    key
    code
    message
    args
  }
`;

export const CriteriaFragment = gql`
  fragment CriteriaFragment on Criteria {
    and {
      elementKey
      op
      input
    }
  }
`;

export const TestFieldFragment = gql`
  fragment TestFieldFragment on TestField {
    key
    value
    dataType
  }
`;

export const TestDataFragment = gql`
  fragment TestDataFragment on TestData {
    tableDefID
    records {
      fields {
        ...TestFieldFragment
      }
    }
  }
  ${TestFieldFragment}
`;
export const SchemaFragment = gql`
  fragment SchemaFragment on Schema {
    elements {
      ...ElementFragment
    }
  }
  ${ElementFragment}
`;

export const TestCaseFragment = gql`
  fragment TestCaseFragment on TestCase {
    key
    name
    comment
    input {
      tableDefID
      records {
        fields {
          ...TestFieldFragment
        }
      }
    }
    variables {
      key
      val
    }
    warnings {
      ...IssueFragment
    }
  }
  ${IssueFragment}
  ${TestFieldFragment}
`;

export const FlowTableDefFragment = gql`
  fragment FlowTableDefFragment on FlowTableDef {
    ID
    name
    comment
    schema {
      ...SchemaFragment
    }
    prefSource
    requiresData
    isInput
    isOutput
    isIncluded
    includedFrom {
      flowID
      flowName
      modelID
      modelName
    }
    required
    issues {
      ...IssueFragment
    }
    onLoad {
      selectWhere {
        and {
          elementKey
          op
          input
        }
      }
    }
    onExport {
      beforeWrite {
        delete {
          where {
            and {
              elementKey
              op
              input
            }
          }
        }
      }
    }
  }
  ${SchemaFragment}
  ${IssueFragment}
`;

export const InputSourceV2Fragment = gql`
  fragment InputSourceV2Fragment on InputSourceV2 {
    flowID
    mode
    tableID
    table @client {
      ID
      name
      comment
      prefSource
      schema {
        elements {
          key
          label
          type
          comment
        }
      }
    }
  }
`;

export const StepSchemaV2Fragment = gql`
  fragment StepSchemaV2Fragment on StepSchemaV2 {
    elements {
      ...StageElementFragment
    }
  }
  ${StageElementFragment}
`;

export const FlowV2Fragment = gql`
  fragment FlowV2Fragment on Flow {
    ID
    ownerID
    containerID
    name
    comment
    stagesV2 {
      ID
      name
      runnable
    }
    stages {
      stageID
      isIncluded
      includedFrom {
        flowID
        flowName
        modelName
        modelID
      }
    }
    runSequence {
      key
      stage {
        stageID
        name
        comment
        runnable
      }
      flow {
        flowID
        name
        comment
        modelID
        modelName
        runnable
      }
    }
    data {
      ...FlowTableDefFragment
    }
    tests {
      ...TestCaseFragment
    }
    userEnv {
      defaultVal
      isIncluded
      toElement {
        ...ElementFragment
      }
      includedFrom {
        flowID
        flowName
        modelName
        modelID
      }
    }
    includes {
      flowID
      flowName
      modelName
      modelID
    }
    includableStages {
      modelID
      modelName
      flowID
      flowName
      stageID
      stageName
    }
    runnable
    createdAt
    formatVersion
  }
  ${FlowTableDefFragment}
  ${TestCaseFragment}
  ${ElementFragment}
`;

export const FlowListItemFragmentNew = gql`
  fragment FlowListItemFragmentNew on FlowListItem {
    ID
    name
    comment
    modelID
    modelName
    runnable
  }
`;

export const FlowListItemFragment = gql`
  fragment FlowListItemFragment on Flow {
    ID
    containerID
    name
    comment
    runnable
  }
`;

export const FuncSummaryFragment = gql`
  fragment FuncSummaryFragment on FunctionV3 {
    ID
    name
    description
    inputs {
      ID
      label
      comment
    }
    outputs {
      ID
      label
      comment
    }
  }
`;

export const StepV2Fragment = gql`
  fragment StepV2Fragment on StepV2 {
    key
    stageID
    title
    comment
    inputSchema {
      ...StepSchemaV2Fragment
    }
    issues {
      ...IssueFragment
    }

    ... on AggregateStepV2 {
      groupBy
      aggregations {
        op
        fromElementKey
        toElement {
          ...ElementFragment
        }
      }
    }

    ... on CombineStepV2 {
      where {
        ...CriteriaFragment
      }
      includeNoMatch
      input {
        ...InputSourceV2Fragment
      }
    }

    ... on ExecStepV2 {
      functionID
      function {
        ...FuncSummaryFragment
      }
      inputMappings {
        fromElementKey
        toFuncInput
      }
    }

    ... on FillStepV2 {
      fields {
        toElement {
          ...ElementFragment
        }
        startValue
        op
        amount
        ... on DateSequenceV2 {
          dateUnit: unit
        }
        ... on NumberSequenceV2 {
          numberUnit: unit
        }
      }
      limit {
        type
        elementKey
        value
      }
    }

    ... on FilterStepV2 {
      or {
        ...CriteriaFragment
      }
    }

    ... on GroupAndSortStepV2 {
      group {
        fromElementKey
        sortDir
      }
      sort {
        fromElementKey
        sortDir
      }
      extFields {
        extType
        fromElementKey
        toElement {
          ...ElementFragment
        }
        param1
      }
    }

    ... on OutputStepV2 {
      tableID
      mappings {
        fromElementKey
        toFieldKey
      }
      filter {
        ...CriteriaFragment
      }
      suspended
      beforeWrite {
        delete {
          where {
            ...CriteriaFragment
          }
        }
      }
    }

    ... on SliceStepV2 {
      toElements {
        ...ElementFragment
      }
      fromKeys
    }
  }

  ${FuncSummaryFragment}
  ${InputSourceV2Fragment}
  ${StepSchemaV2Fragment}
  ${IssueFragment}
  ${ElementFragment}
  ${CriteriaFragment}
`;

export const FlowStageV2Fragment = gql`
  fragment FlowStageV2Fragment on FlowStageV2 {
    ID
    flowID
    name
    description
    runnable

    input {
      ...InputSourceV2Fragment
    }
    steps {
      ...StepV2Fragment
    }
    issues {
      ...IssueFragment
    }
  }
  ${InputSourceV2Fragment}
  ${StepV2Fragment}
  ${IssueFragment}
`;

export const StageLogFragment = gql`
  fragment StageLogFragment on StageLog {
    title
    stageID
    stepLogs {
      title
      key
      schema {
        ...StepSchemaV2Fragment
      }
      toWrite
      inputElementKeys
      logItems {
        seq
        data
        src
        flags
      }
    }
  }
  ${StepSchemaV2Fragment}
`;
