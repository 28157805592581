import React, {
  useEffect,
  useMemo,
  useContext,
  Fragment,
  useState,
} from 'react';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { useQuery } from '<src>/apollo/client';

import DocumentTitle from 'react-document-title';
import fp from 'lodash/fp';
import MainLayout from '<components>/MainLayout';
import {
  FlowContainer,
  Placeholder,
  InfoContainer,
} from '<src>/components/NumbrzVerticalEditor';
import { PageContainer, ContentWrapper, TestSidebar } from '../styles';
import NotFound from '<components>/Routes/NotFound';
import WaveSpinner from '<components>/WaveSpinner';
import useFeatures from '<components>/Feature/useFeatures';

import FlowOverview from '../FlowOverview';
import FlowSidebar from '../FlowSidebarV5';
import StageEditor from '../StageEditor';
import EmbeddedFlowViewer from '../EmbeddedFlowViewer';
import TableEditor from '../TableEditor';
import TestEditor from '../flow-testing/TestEditor';
import TestResults from '../flow-testing/TestResults';
import UserVariablesEditor from '../UserVariablesEditor';

import useFlowState from '../api/useFlowState';
import useStageState from '../api/useStageState';
import useTableState from '../api/useTableState';
import useFlowTestState from '../flow-testing/useFlowTestState';
import { UserPreferencesContext } from '<components>/UserPreferences';

import {
  FlexColumnWrapper,
  ColumnWrapper,
  PageContent,
  EmptyListContainer,
  CardList,
  CardListItem,
  FlexRowContainer,
  ListContent,
  ErrorChiclet,
} from '<components>/NumbrzPageComponents';
import FlowTestMasthead from '../flow-testing/FlowTestMasthead';
import FlowMasthead from '../FlowMasthead';
import ResizeableSplitView from '../flow-testing/ResizeableSplitView';
import { Icon } from 'semantic-ui-react';
import Card from '<src>/components/Card';
import Link from '<src>/components/Link';
import { NavItemChiclet, IncludedSrcLink } from '../FlowItemList';
import { publish } from '<components>/events';
import { NumbrzTooltip } from '<components>/NumbrzButtons';
import Button from '<src>/components/Button';
import AddNameDialog from '<src>/components/AddNameDialog';
import AddJobTableDialog from '../AddJobTableDialog';
import IncludeFlowDialog from '../IncludeFlowDialog';
import ActionMenu from '<components>/ActionMenu';
import FlowPageMasthead from '../FlowPageMasthead';
import { GetFlow } from '../api/queries';

function LoadStageEditor({
  baseURL,
  flow,
  flowEnv,
  stageID,
  stageState,
  flowSettings = {},
  testResults = [],
  setFlowSetting,
  runTest,
  enterTestMode,
  testMode,
  testFeature,
  dataTables,
  dataTablesLoading,
  fetchDataTables,
}) {
  const { api, called, functions, loading, loadStage, stage } = stageState;
  const isIncluded = stage && stage.flowID !== flow.ID;
  const storedFlow = flowSettings[flow.ID];
  const activeTest =
    storedFlow && storedFlow.activeTest
      ? JSON.parse(storedFlow.activeTest)
      : {};

  const canRunTest = !!storedFlow && testResults.length === 0;

  useEffect(() => {
    fetchDataTables();
    if (activeTest.key && canRunTest) runTest(activeTest.key);

    setFlowSetting(flow.ID, 'navActiveEmbeddedFlow', null);
    setFlowSetting(flow.ID, 'navActiveStage', stageID);

    loadStage(stageID);
  }, [stageID, activeTest.key, canRunTest, flow.ID]);

  return (
    <Fragment>
      <FlowMasthead
        enterTestMode={enterTestMode}
        testMode={testMode}
        testFeature={testFeature}
        title={stage ? stage.name : undefined}
        onTitleChange={
          isIncluded ? null : (e) => api.onChange(e, { name: e.value })
        }
        flowID={flow.ID}
      />
      <ColumnWrapper>
        {(loading || !called) && <WaveSpinner />}
        <PageContent maxWidth="1000px">
          {!stage && called && !loading && (
            <Placeholder>
              <NotFound />
            </Placeholder>
          )}

          {stage && (!loading || called) && (
            <StageEditor
              baseURL={baseURL}
              flow={flow}
              jobVariables={flowEnv.elements}
              stage={stage}
              functions={functions}
              api={api}
              enterTestMode={enterTestMode}
              testMode={testMode}
              testFeature={testFeature}
              dataTables={dataTables}
              dataTablesLoading={dataTablesLoading}
            />
          )}
        </PageContent>
      </ColumnWrapper>
    </Fragment>
  );
}

function LoadEmbeddedFlow({
  baseURL,
  flow,
  embeddedFlowID,
  flowSettings = {},
  testResults = [],
  runTest,
  enterTestMode,
  testMode,
  testFeature,
  setFlowSetting,
}) {
  const { loading, data: embeddedFlowData } = useQuery(GetFlow, {
    variables: { flowID: embeddedFlowID },
    fetchPolicy: 'network-only',
  });

  const embeddedFlow = embeddedFlowData?.flow?.flow;

  const storedFlow = flowSettings[flow.ID];
  const activeTest =
    storedFlow && storedFlow.activeTest
      ? JSON.parse(storedFlow.activeTest)
      : {};
  const canRunTest = !!storedFlow && testResults.length === 0;

  useEffect(() => {
    if (activeTest.key && canRunTest) runTest(activeTest.key);
    setFlowSetting(flow.ID, 'navActiveStage', null);
    if (embeddedFlow)
      setFlowSetting(flow.ID, 'navActiveEmbeddedFlow', embeddedFlow.ID);
  }, [activeTest.key, canRunTest, flow.ID, runTest, embeddedFlow]);

  return (
    <Fragment>
      <FlowMasthead
        enterTestMode={enterTestMode}
        testMode={testMode}
        testFeature={testFeature}
        title={embeddedFlow ? embeddedFlow.name : undefined}
        onTitleChange={null}
        flowID={flow.ID}
        titleNavPath={`/models/${embeddedFlow?.containerID}/flows/${embeddedFlow?.ID}`}
      />
      <ColumnWrapper>
        {loading && <WaveSpinner />}
        <PageContent maxWidth="1000px">
          <EmbeddedFlowViewer
            baseURL={baseURL}
            embeddedFlow={embeddedFlow}
            loading={loading}
          />
        </PageContent>
      </ColumnWrapper>
    </Fragment>
  );
}

function LoadTableEditor({
  baseURL,
  flow,
  flowEnv,
  isWorkingTable,
  tableID,
  tableState,
  enterTestMode,
  testMode,
  testFeature,
}) {
  const {
    api,
    called,
    loadTable,
    unloadTable,
    table,
    dataTables,
    dataTablesLoading,
  } = tableState;

  useEffect(() => {
    loadTable(tableID);

    return () => unloadTable();
  }, [loadTable, tableID, unloadTable]);
  if (table) {
    if (isWorkingTable && table.prefSource !== 'WorkingTable') {
      return <Redirect to={`${baseURL}/job-tables/${tableID}`} />;
    }
    if (!isWorkingTable && table.prefSource === 'WorkingTable') {
      return <Redirect to={`${baseURL}/working-tables/${tableID}`} />;
    }
  }

  return (
    <Fragment>
      <FlowMasthead
        enterTestMode={enterTestMode}
        testMode={testMode}
        testFeature={testFeature}
        title={table ? table.name : undefined}
        onTitleChange={
          table && table.isIncluded
            ? null
            : (e) => api.onChange(e, { name: e.value })
        }
        flowID={flow.ID}
        maxTitleWidth="200px"
      >
        {table && table.isIncluded && (
          <NavItemChiclet margin="0 5px 0 10px">
            <IncludedSrcLink item={table}>Included</IncludedSrcLink>
          </NavItemChiclet>
        )}
        {table && table.required && (
          <NavItemChiclet margin="0 0 0 5px">Required</NavItemChiclet>
        )}
      </FlowMasthead>

      <ColumnWrapper>
        {!called && <WaveSpinner />}

        <PageContent>
          {!table && (
            <Placeholder>
              <h2>Not Found</h2>
            </Placeholder>
          )}
          {called && table && (
            <TableEditor
              flow={flow}
              jobVariables={flowEnv.elements}
              table={table}
              api={api}
              dataTables={dataTables}
              dataTablesLoading={dataTablesLoading}
              enterTestMode={enterTestMode}
              testMode={testMode}
              testFeature={testFeature}
            />
          )}
        </PageContent>
      </ColumnWrapper>
    </Fragment>
  );
}

function LoadTestEditor({
  baseURL,
  flowState,
  testCaseKey,
  enterTestMode,
  testMode,
  testFeature,
  flowID,
}) {
  const history = useHistory();
  const testCase = flowState.flow.tests.find((tC) => tC.key === testCaseKey);
  if (!testCase) history.replace(`${baseURL}/tests`);

  return (
    <TestEditor
      baseURL={baseURL}
      flowState={flowState}
      testCaseKey={testCaseKey}
      enterTestMode={enterTestMode}
      testMode={testMode}
      testFeature={testFeature}
      flowID={flowID}
    />
  );
}

function FlowEntityList({
  objType,
  items,
  urlPath,
  onAddItem,
  onRemoveItem,
  onCloneItem,
  skipNameDialog = false,
  dataTables = [],
  dataTablesLoading,
  flowID,
  actionLbl = 'Create',
  objLbl,
}) {
  const [dialogVisible, setDialogVisible] = useState(false);

  const onAddClicked = skipNameDialog
    ? onAddItem
    : () => setDialogVisible(true);

  const DialogComp =
    objType === 'job table' || objType === 'working table'
      ? AddJobTableDialog
      : objType === 'included'
        ? IncludeFlowDialog
        : AddNameDialog;

  const DialogAddProps =
    objType === 'job table' || objType === 'working table'
      ? { dataTables, dataTablesLoading }
      : objType === 'included'
        ? {
            flowID,
            includedFlows: items,
            onIncludeFlows: (e, newRefs) => onAddItem(e, newRefs),
          }
        : {};

  return (
    <Fragment>
      <ColumnWrapper>
        <ListContent>
          {items.length === 0 && (
            <EmptyListContainer>
              <h4>No items found</h4>
            </EmptyListContainer>
          )}
          <CardList>
            {items.map((item) => {
              return (
                <CardListItem>
                  <Card.Header>
                    <FlexRowContainer
                      alignItems="baseline"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <FlexRowContainer
                        alignItems="center"
                        justifyContent="flex-start"
                        style={{ flexWrap: 'wrap', rowGap: '10px' }}
                      >
                        <Link
                          internal
                          to={`${urlPath}/${item.ID}`}
                          style={{ paddingLeft: 0 }}
                        >
                          {item.name || item.flowName}
                        </Link>
                        {item.isIncluded && (
                          <NavItemChiclet margin="0 5px 0 0px">
                            <IncludedSrcLink item={item}>
                              Included
                            </IncludedSrcLink>
                          </NavItemChiclet>
                        )}
                        {item.prefSource !== 'WorkingTable' &&
                          item.required === true && (
                            <NavItemChiclet margin="0 0 0 5px">
                              Required
                            </NavItemChiclet>
                          )}
                      </FlexRowContainer>
                      {onRemoveItem || onCloneItem ? (
                        <ActionMenu
                          onClick={(e) => e.preventDefault()}
                          options={[
                            ...(onRemoveItem
                              ? [
                                  ActionMenu.deleteOption({
                                    label:
                                      item.__typename === 'IncludeFlow' ||
                                      item.isIncluded
                                        ? 'Exclude'
                                        : null,
                                    onSelect: (e) => onRemoveItem(e, item),
                                  }),
                                ]
                              : []),
                            ...(onCloneItem
                              ? [
                                  ActionMenu.cloneOption({
                                    onSelect: (e) => onCloneItem(e, item),
                                  }),
                                ]
                              : []),
                          ]}
                        />
                      ) : null}
                    </FlexRowContainer>

                    <NumbrzTooltip
                      content={<span>This item contains issues</span>}
                      trigger={
                        <ErrorChiclet visible={!item.valid ? 'true' : 'false'}>
                          <Icon name="circle" />
                          <Icon name="exclamation" />
                        </ErrorChiclet>
                      }
                    />
                  </Card.Header>
                </CardListItem>
              );
            })}
          </CardList>
        </ListContent>
        <Button.ListPageCreateBtn onClick={onAddClicked} title={actionLbl}>
          {`${actionLbl} ${objLbl}`}
          {objType !== 'included' && <span className="icon">+</span>}
        </Button.ListPageCreateBtn>
      </ColumnWrapper>

      {!skipNameDialog ? (
        <DialogComp
          objType={objType}
          onClose={() => setDialogVisible(false)}
          onCreate={onAddItem}
          visible={dialogVisible}
          {...DialogAddProps}
        />
      ) : null}
    </Fragment>
  );
}

export function FlowPageContent({
  project,
  features,
  flowState,
  flowTestState,
  stageState,
  flowSettings = {},
  setFlowSetting,
  tableState,
  testMode,
  exitTestMode,
  enterTestMode,
  testModeExpanded,
  setTestModeExpanded,
  rightWidth,
  setRightWidth,
  setUserPreference,
}) {
  const { loading, error, flow, environment, api = {} } = flowState;
  const { runTest, setTestResults } = flowTestState;
  const { dataTables, dataTablesLoading, fetchDataTables } = tableState;

  const match = useRouteMatch();
  const history = useHistory();

  const {
    onAddTable,
    onRemoveTable,
    onAddTestCase,
    onCloneTestCase,
    onRemoveTestCase,
    onChange,
  } = api;

  if (error) {
    throw error;
  }
  const [onAddJobTable, onAddWorkingTable] = useMemo(() => {
    if (!onAddTable) {
      return [null, null];
    }

    const doAddTable = async (e, defn, typePath) => {
      const table = await onAddTable(e, defn);

      history.push(`${match.url}/${typePath}/${table.ID}`);
    };

    const onAddJobTable = (tablePayload, e) =>
      doAddTable(
        e,
        {
          ...tablePayload,
          prefSource: 'Any',
        },
        'job-tables'
      );
    const onAddWorkingTable = (tablePayload, e) =>
      doAddTable(
        e,
        {
          ...tablePayload,
          prefSource: 'WorkingTable',
        },
        'working-tables'
      );

    return [onAddJobTable, onAddWorkingTable];
  }, [match.url, history, onAddTable]);

  const defaultFlowMasthead = useMemo(
    () => (
      <FlowMasthead
        enterTestMode={enterTestMode}
        testMode={testMode}
        testFeature={features.FlowTesting}
        flowID={loading ? null : flow.ID}
        title={flow && flow.name}
        onTitleChange={(e) => onChange(e, { name: e.value })}
      />
    ),
    [enterTestMode, testMode, features.FlowTesting, flow, loading, onChange]
  );

  const onAddTest = useMemo(() => {
    if (!onAddTestCase || !flow) {
      return null;
    }
    return async (name, e) => {
      const newTest = await onAddTestCase(name);
      if (newTest) {
        setFlowSetting(flow.ID, 'navActiveTest', newTest.key);
        history.push(`${match.url}/tests/${newTest.key}`);
      }
    };
  }, [match.url, history, flow]);

  const doCloneTest = async (e, item) => {
    if (!onCloneTestCase) {
      return null;
    }

    const newTest = await onCloneTestCase(e, item);
    if (newTest) {
      setFlowSetting(flow.ID, 'navActiveTest', newTest.key);
      history.push(`${match.url}/tests/${newTest.key}?new`);
    }
  };

  const [jobTables, workingTables] = useMemo(() => {
    const tables = loading
      ? []
      : flow.data.map((t) => ({
          ID: t.key,
          valid: t.issues.length === 0,
          ...t,
        }));
    return [
      tables
        .filter((t) => t.prefSource !== 'WorkingTable')
        .filter(
          (jT) =>
            !jT.isIncluded || (jT.isIncluded && (jT.isInput || jT.isOutput))
        ),
      tables.filter((t) => t.prefSource === 'WorkingTable'),
    ];
  }, [flow, loading]);

  const testCases = useMemo(
    () =>
      loading
        ? []
        : flow.tests.map((tC) => ({
            valid: tC.warnings.length === 0,
            ...tC,
            ID: tC.key,
          })),
    [flow, loading]
  );

  if (loading) return <WaveSpinner />;

  return (
    <PageContainer>
      <ResizeableSplitView
        showDivider={testMode}
        minWidth={400}
        height={'calc(100% - 45px)'}
        rightPanelOpen={testMode}
        rightWidth={rightWidth}
        setRightWidth={setRightWidth}
        rightPanelExpanded={testModeExpanded}
        left={
          <FlexColumnWrapper width="100%" height="100%">
            <ContentWrapper>
              <FlowSidebar
                projectID={project && project.ID}
                flow={flow}
                features={features}
                api={api}
                setTestResults={setTestResults}
                flowSettings={flowSettings[flow.ID]}
                setFlowSetting={setFlowSetting}
                dataTables={dataTables}
                dataTablesLoading={dataTablesLoading}
              />
              <FlowContainer>
                <Switch>
                  <Route path={`${match.url}/documentation`}>
                    <Fragment>
                      <FlowMasthead
                        enterTestMode={enterTestMode}
                        testMode={testMode}
                        testFeature={features.FlowTesting}
                        title={flow.name}
                        onTitleChange={(e) =>
                          api.onChange(e, { name: e.value })
                        }
                        flowID={flow.ID}
                      />
                      <ColumnWrapper>
                        <PageContent>
                          <FlowOverview flow={flow} onChange={api.onChange} />
                        </PageContent>
                      </ColumnWrapper>
                    </Fragment>
                  </Route>
                  <Route path={`${match.url}/variables`}>
                    <Fragment>
                      <FlowMasthead
                        enterTestMode={enterTestMode}
                        testMode={testMode}
                        testFeature={features.FlowTesting}
                        title={flow.name}
                        onTitleChange={(e) =>
                          api.onChange(e, { name: e.value })
                        }
                        flowID={flow.ID}
                      />
                      <ColumnWrapper>
                        <PageContent>
                          <UserVariablesEditor
                            mode="flow"
                            flowID={flow.ID}
                            userVariables={flow.userEnv}
                            onAddUpdateUserVar={api.onAddUpdateUserVar}
                            onRemoveUserVar={api.onRemoveUserVar}
                          />
                        </PageContent>
                      </ColumnWrapper>
                    </Fragment>
                  </Route>
                  <Route path={`${match.url}/stages/embedded/:embeddedFlowID`}>
                    {({ match: innerMatch }) => {
                      const { embeddedFlowID } = innerMatch.params;
                      if (flow.runSequence.length === 0) {
                        return <Redirect to={`${match.url}/stages`} />;
                      }
                      return (
                        <LoadEmbeddedFlow
                          baseURL={match.url}
                          flow={flow}
                          embeddedFlowID={embeddedFlowID}
                          flowSettings={flowSettings}
                          runTest={runTest}
                          testResults={flowTestState.testResults}
                          enterTestMode={enterTestMode}
                          testMode={testMode}
                          testFeature={features.FlowTesting}
                          setFlowSetting={setFlowSetting}
                        />
                      );
                    }}
                  </Route>
                  <Route path={`${match.url}/stages/:stageID`}>
                    {({ match: innerMatch }) => {
                      const { stageID } = innerMatch.params;
                      if (flow.runSequence.length === 0) {
                        return <Redirect to={`${match.url}/stages`} />;
                      }
                      return (
                        <LoadStageEditor
                          baseURL={match.url}
                          flow={flow}
                          flowEnv={environment}
                          stageID={stageID}
                          stageState={stageState}
                          flowSettings={flowSettings}
                          runTest={runTest}
                          testResults={flowTestState.testResults}
                          setFlowSetting={setFlowSetting}
                          setUserPreference={setUserPreference}
                          enterTestMode={enterTestMode}
                          testMode={testMode}
                          testFeature={features.FlowTesting}
                          dataTables={dataTables}
                          dataTablesLoading={dataTablesLoading}
                          fetchDataTables={fetchDataTables}
                        />
                      );
                    }}
                  </Route>

                  <Route exact path={`${match.url}/stages`}>
                    {() => {
                      const storedActiveStageID = fp.getOr(
                        undefined,
                        `[${flow.ID}].navActiveStage`,
                        flowSettings
                      );
                      const storedActiveEmbFlowID = fp.getOr(
                        undefined,
                        `[${flow.ID}].navActiveEmbeddedFlow`,
                        flowSettings
                      );

                      const activeStage = storedActiveStageID
                        ? flow.runSequence.find(
                            (s) => s.stage?.stageID === storedActiveStageID
                          )
                        : null;
                      const activeEmbFlow = storedActiveEmbFlowID
                        ? flow.runSequence.find(
                            (s) => s.flow?.flowID === storedActiveEmbFlowID
                          )
                        : null;

                      if (activeStage) {
                        return (
                          <Redirect
                            to={`${match.url}/stages/${activeStage.stage.stageID}`}
                          />
                        );
                      }
                      if (activeEmbFlow) {
                        return (
                          <Redirect
                            to={`${match.url}/stages/embedded/${activeEmbFlow.flow.flowID}`}
                          />
                        );
                      }
                      if (flow.runSequence[0]?.stage?.stageID) {
                        return (
                          <Redirect
                            to={`${match.url}/stages/${flow.runSequence[0].stage.stageID}`}
                          />
                        );
                      }
                      if (flow.runSequence[0]?.flow?.flowID) {
                        return (
                          <Redirect
                            to={`${match.url}/stages/embedded/${flow.runSequence[0].flow.flowID}`}
                          />
                        );
                      }
                      return (
                        <Fragment>
                          {defaultFlowMasthead}
                          <ColumnWrapper>
                            <InfoContainer margin={'20px 0'}>
                              This flow has no stages defined.
                            </InfoContainer>
                          </ColumnWrapper>
                        </Fragment>
                      );
                    }}
                  </Route>
                  <Route path={`${match.url}/job-tables/:tableID`}>
                    {({ match }) => {
                      const { tableID } = match.params;
                      return (
                        <LoadTableEditor
                          baseURL={match.url}
                          flow={flow}
                          flowEnv={environment}
                          isWorkingTable={false}
                          tableID={tableID}
                          tableState={tableState}
                          enterTestMode={enterTestMode}
                          testMode={testMode}
                          testFeature={features.FlowTesting}
                        />
                      );
                    }}
                  </Route>
                  <Route exact path={`${match.url}/job-tables`}>
                    {() => {
                      return (
                        <Fragment>
                          {defaultFlowMasthead}
                          <FlowEntityList
                            flowState={flowState}
                            objType="job table"
                            items={jobTables}
                            urlPath={`${match.url}/job-tables`}
                            onAddItem={onAddJobTable}
                            onRemoveItem={onRemoveTable}
                            dataTables={dataTables}
                            dataTablesLoading={dataTablesLoading}
                            actionLbl="Create a"
                            objLbl="table"
                          />
                        </Fragment>
                      );
                    }}
                  </Route>
                  <Route path={`${match.url}/working-tables/:tableID`}>
                    {({ match: innerMatch }) => {
                      const { tableID } = innerMatch.params;
                      return (
                        <LoadTableEditor
                          baseURL={match.url}
                          flow={flow}
                          flowEnv={environment}
                          isWorkingTable
                          tableID={tableID}
                          tableState={tableState}
                          enterTestMode={enterTestMode}
                          testMode={testMode}
                          testFeature={features.FlowTesting}
                        />
                      );
                    }}
                  </Route>
                  <Route path={`${match.url}/working-tables`}>
                    {() => {
                      return (
                        <Fragment>
                          {defaultFlowMasthead}
                          <FlowEntityList
                            objType="working table"
                            items={workingTables}
                            urlPath={`${match.url}/working-tables`}
                            onAddItem={onAddWorkingTable}
                            onRemoveItem={onRemoveTable}
                            dataTables={dataTables}
                            dataTablesLoading={dataTablesLoading}
                            actionLbl="Create a"
                            objLbl="table"
                          />
                        </Fragment>
                      );
                    }}
                  </Route>
                  <Route path={`${match.url}/tests/:testCaseKey`}>
                    {({ match: innerMatch }) => {
                      const { testCaseKey } = innerMatch.params;

                      return (
                        <LoadTestEditor
                          baseURL={match.url}
                          flow={flow}
                          flowState={flowState}
                          testCaseKey={testCaseKey}
                          enterTestMode={enterTestMode}
                          testMode={testMode}
                          testFeature={features.FlowTesting}
                          flowID={flow.ID}
                        />
                      );
                    }}
                  </Route>
                  <Route path={`${match.url}/tests`}>
                    {() => {
                      return (
                        <Fragment>
                          {defaultFlowMasthead}
                          <FlowEntityList
                            objType="test"
                            items={testCases}
                            urlPath={`${match.url}/tests`}
                            onAddItem={onAddTest}
                            onRemoveItem={onRemoveTestCase}
                            onCloneItem={doCloneTest}
                            actionLbl="Create a"
                            objLbl="test"
                          />
                        </Fragment>
                      );
                    }}
                  </Route>

                  <Route>
                    <Redirect to={`${match.url}/stages`} />
                  </Route>
                </Switch>
              </FlowContainer>
            </ContentWrapper>
          </FlexColumnWrapper>
        }
        right={
          testMode ? (
            <TestSidebar>
              <FlowTestMasthead
                exitTestMode={exitTestMode}
                testModeExpanded={testModeExpanded}
                setTestModeExpanded={setTestModeExpanded}
              />
              <TestResults
                baseURL={match.url}
                flowID={flow.ID}
                jobVariables={environment && environment.elements}
                tests={flow.tests || []}
                flowStages={flow.stagesV2 || []}
                flowTestState={flowTestState}
                activeStage={stageState.stage}
                flowTables={flow.data}
              />
            </TestSidebar>
          ) : null
        }
      />
    </PageContainer>
  );
}

export default function FlowPage({
  flowID,
  flowData,
  loaddingFlow,
  flowError,
  project,
  match,
  history,
}) {
  const features = useFeatures();
  const flowState = useFlowState(flowID, flowData, loaddingFlow, flowError);
  const stageState = useStageState(flowState.flow);
  const tableState = useTableState(flowState.flow);

  const {
    userPreferences,
    setUserPreference,
    setUserPreferences,
    flowSettings,
    setFlowSetting,
  } = useContext(UserPreferencesContext);
  const { testMode, testModeFullscreen, testSidebarWidth, leftSidebar } =
    userPreferences;

  const { loading, flow = {}, api } = flowState;
  const flowTestState = useFlowTestState(flowID, api, flow.tests);

  const name = flow ? flow.name : 'Flow';

  const handleEnterTestMode = () => {
    const pathname = fp.getOr(undefined, 'location.pathname', history);
    if (pathname && !pathname.includes('stages')) {
      const activeStageID =
        fp.getOr(undefined, 'stage.ID', stageState) ||
        fp.getOr('', 'stagesV2[0].ID', flow);

      history.push(`${match.url}/stages/${activeStageID}`);
    }

    publish(!testMode === true ? 'enterTestMode' : 'exitTestMode');
    !testMode === false && setUserPreference('testModeFullscreen', false);

    setUserPreference('testMode', !testMode);
  };

  const handleExitTestMode = () => {
    publish('exitTestMode');
    setUserPreferences({
      testMode: false,
      testModeFullscreen: false,
    });
  };

  return (
    <DocumentTitle title={`${loading ? 'Loading' : name} - Numbrz`}>
      <MainLayout
        navigation={false}
        header={
          <FlowPageMasthead
            project={project}
            flow={flow}
            matchURL={match.url}
            loading={loading}
          />
        }
        main={
          <FlowPageContent
            features={features}
            project={project}
            flowState={flowState}
            flowTestState={flowTestState}
            stageState={stageState}
            flowSettings={flowSettings}
            setFlowSetting={setFlowSetting}
            tableState={tableState}
            testMode={testMode}
            enterTestMode={handleEnterTestMode}
            exitTestMode={handleExitTestMode}
            leftSidebar={leftSidebar}
            testModeExpanded={testModeFullscreen}
            setTestModeExpanded={setUserPreference}
            rightWidth={testSidebarWidth}
            setRightWidth={(value) =>
              setUserPreference('testSidebarWidth', value)
            }
            setUserPreference={setUserPreference}
          />
        }
      />
    </DocumentTitle>
  );
}
