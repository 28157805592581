import React, { useMemo, useState, useEffect, useRef } from 'react';
import fp from 'lodash/fp';
import styled from '@emotion/styled';

import { useQuery } from '<src>/apollo/client';

import Dialog from '<components>/Dialog';
import Button from '<components>/Button';
import { Dropdown } from 'semantic-ui-react';

import { ListFlows } from '../api/queries';
import WaveSpinner from '<src>/components/WaveSpinner';
import TextInput from '<components>/TextInput';
import { SectionHdr } from '<components>/DataPicker/styles';

const StyledTextInput = styled(TextInput)`
  width: 100%;
  min-width: 400px;
`;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
  border: 1px solid #dbdbdc;
  border-radius: 0.28571429rem;
  padding: 10px;

  input {
    &:focus {
      outline: 1px solid #0d89d7 !important;
      border-radius: 0.28571429rem;
      height: 40px;
    }
  }
`;

export default function AddStageDialog({ flowID, visible, onClose, onCreate }) {
  const [name, setName] = useState(`New stage`);
  const [importFlow, setImportFlow] = useState();
  const inputRef = useRef();
  const [creating, setCreating] = useState(false);

  const { loading, data = {} } = useQuery(ListFlows, {
    variables: { formatVersion: 5 },
  });

  const flowsList = useMemo(() => {
    return loading
      ? []
      : fp.getOr([], 'listFlows', data).filter((f) => f.ID !== flowID);
  }, [data, flowID, loading]);

  const flowOpts = useMemo(
    () =>
      flowsList.map((flow, idx) => ({
        key: idx,
        text: flow.name,
        value: flow.ID,
      })),
    [flowsList]
  );

  const showImportUI = flowsList.length > 0;

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (isValid) return handleSubmit(e);
    }
  };

  const isValid = useMemo(() => {
    if (!importFlow) {
      return name && name.trim();
    } else {
      return importFlow !== undefined;
    }
  }, [name, importFlow]);

  useEffect(() => {
    if (!visible) {
      setCreating(false);
      setName(`New stage`);
      setImportFlow(null);
    } else {
      setTimeout(() => {
        inputRef.current.focus();
        inputRef.current.select();
      }, 0);
    }
  }, [visible]);

  const handleSubmit = async (e) => {
    setCreating(true);
    const payload = importFlow
      ? {
          flowID,
          flow: {
            flowID: importFlow,
          },
        }
      : {
          flowID,
          stage: {
            name: name.trim(),
          },
        };

    await onCreate(payload);
    onClose();
  };

  return (
    <Dialog onRequestClose={onClose} visible={visible}>
      <Dialog.Header>
        <Dialog.Headline>Add New</Dialog.Headline>
      </Dialog.Header>
      <Dialog.Body>
        {showImportUI && <SectionHdr>Option 1: Add a new stage</SectionHdr>}
        <StyledTextInput
          ref={inputRef}
          placeholder={`Stage name (Required)`}
          name="name"
          onChange={({ target: { name, value } }) => {
            setName(value);
            if (importFlow) setImportFlow(undefined);
          }}
          disabled={creating}
          onKeyDown={handleKeyDown}
          value={name}
        />
      </Dialog.Body>
      {showImportUI && (
        <Dialog.Body>
          <SectionHdr>Option 2: Add stages from another flow</SectionHdr>
          {loading ? (
            <WaveSpinner />
          ) : (
            <StyledDropdown
              floating
              labeled
              options={flowOpts}
              search
              placeholder="Select flow"
              value={importFlow}
              onChange={(e, { value }) => {
                setName(``);
                setImportFlow(value);
              }}
            />
          )}
        </Dialog.Body>
      )}

      <Dialog.Footer>
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={!isValid} onClick={handleSubmit}>
          Add
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}
