import React, { useRef, useState } from 'react';
import T from 'prop-types';

import Button from '<src>/components/Button';
import { Dropdown, Icon } from 'semantic-ui-react';

import styled from '@emotion/styled';
import * as colors from '<components>/colors';

const Container = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 4px 4px 10px;
  margin-bottom: 3px;
  border: 1px solid ${colors.blue4};
  border-radius: 0.28571429rem;
  background-color: white;
`;

const StyledInput = styled('input', {
  shouldForwardProp: (prop) => prop !== 'innerRef',
})`
  flex: 3;
  border: none !important;
  margin: 0 10px 0 0 !important;
  padding: 0 !important;
  &:focus {
    border-bottom: 1px solid ${colors.blue4} !important;
    border-radius: 0 !important;
    outline: none;
  }
  &::placeholder {
    color: ${colors.gray2};
  }
`;

const StyledType = styled('div')`
  flex: 2;
`;

SchemaElementInput.propTypes = {
  addSchemaElement: T.func.isRequired,
  typeOptions: T.arrayOf(
    T.shape({ value: T.string.isRequired, text: T.string.isRequired })
  ).isRequired,
  defaultValue: T.string,
};

export default function SchemaElementInput({
  defaultValue = undefined,
  typeOptions = [],
  addSchemaElement,
}) {
  const [label, setLabel] = useState('');
  const [type, setType] = useState(defaultValue);
  const textRef = useRef(null);
  const buttonRef = useRef(null);

  const isValid = () => {
    return label && type;
  };

  const handleAddElement = async (cb = () => {}) => {
    if (!isValid()) return;
    setLabel('');
    setType(defaultValue);
    await addSchemaElement(label, type);
    cb();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      handleAddElement(() => textRef.current.focus());
    }
  };

  const activeType = typeOptions.find((t) => t.value === type);

  return (
    <Container>
      <StyledInput
        ref={textRef}
        value={label}
        onChange={(e) => setLabel(e.target.value)}
        placeholder="Field Label"
        onKeyDown={handleKeyDown}
      />
      <StyledType>
        <Dropdown
          closeOnBlur
          placeholder="Type"
          value={activeType ? activeType.value : null}
          options={typeOptions}
          onChange={(e, data) => setType(data.value)}
          onClose={() => {
            if (isValid()) {
              buttonRef.current && buttonRef.current.focus();
            } else {
              textRef.current && textRef.current.focus();
            }
          }}
        />
      </StyledType>
      <Button.IconBtn
        ref={buttonRef}
        forwardedRef={buttonRef}
        icon={<Icon name="plus" title="Add schema element" />}
        disabled={!isValid()}
        onClick={() => handleAddElement()}
        onKeyDown={handleKeyDown}
      />
    </Container>
  );
}
